import React from "react";

const NewBadge = () => <span className="new-badge">NEW!</span>;

const SongsList = ({ isLoading, filteredSongs }) => {
  return (
    <div className="table-container">
      <table>
        {!isLoading ? (
          <>
            <thead>
              <tr>
                <th>Artist</th>
                <th>Song</th>
              </tr>
            </thead>
            <tbody>
              {filteredSongs.length ? (
                filteredSongs.map((song, index) => (
                  <tr key={index}>
                    <td className={song.IS_NEW && "new-song"}>
                      <NewBadge />
                      <span>{song.ARTIST}</span>
                    </td>
                    <td>{song.TITLE}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="2" style={{ textAlign: "center" }}>
                    Sorry, we don't have that song currently. You can always
                    request it!
                  </td>
                </tr>
              )}
            </tbody>
          </>
        ) : (
          <></>
        )}
      </table>
    </div>
  );
};

export default SongsList;
